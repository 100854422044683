import React from 'react';
import './consult-doctor.scss';
import ExternalLink from './external-link';

const ConsultDoctor = ({bgColor}) => {
  return (
    <section className={bgColor==="blue" ? "bottom-container-blue": "bottom-container-yellow"}>
      <div className={`${bgColor==="blue"? "text-white": "text-black"} container bottom px-5 py-4 text-center`}>
        <p>
          Ask your doctor if VABYSMO is right for you. For more information, talk to your healthcare professional; 
          
          or
          visit{' '}
          <ExternalLink href="https://www.medsafe.govt.nz/Consumers/CMI/v/vabysmo.pdf" className="consult-doctor-link">
            medsafe.govt.nz
          </ExternalLink>{' '}
          for the VABYSMO Consumer Medicine Information
          
        </p>
      </div>
    </section>
  );
};

export default ConsultDoctor;
